import axios from "axios";

const auth = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    },
};
export default {

	numberReset: function (alertTYPE, number) {
			this.$swal({
				html:
					`<h5 style="margin-bottom: 0">Reset ${number + " " + alertTYPE} Notifications</h5>` +
					'<br/>' +
					'<div style="overflow:scroll; max-height: 300px; text-align: left;"><small>' +
					`<p>Are you sure you want to clear ${alertTYPE.toLowerCase()}s without printing? It will clear the number on your notifications, and remove the items from this list.</p>` +
					'</small></div>',
				showCloseButton: true,
				showCancelButton: true,
				confirmButtonText: `Reset`,
				denyButtonText: `Cancel`,
				customClass: {
					cancelButton: 'order-1 right-gap',
					confirmButton: 'order-2',
				}
			}).then((result) => {
				if (result.isConfirmed) {
                    if(alertTYPE == 'Acknowledgment'){
                        return axios.get(
                    `https://ordermanage.spacesaver.com/SetAllPDFs?TYPE=confirmation`,
                    auth
                )
                .then(response => {
                    console.log(response);
                    
                    //window.open('/acknowledgments');
                    //this.$forceUpdate();
                    this.$router.push("/listings");
					this.$noty.success("Acknowledgment notifications are now cleared");
                    return '';
                });

                    } else {
                        return axios.get(
                            `https://ordermanage.spacesaver.com/SetAllPDFs?TYPE=invoice`,
                            auth
                        )
                        .then(response => {
                            console.log(response);
                            //window.open('/invoices');
                            //this.$forceUpdate();
                            this.$router.push("/listings");
                            this.$noty.success("Invoice notifications are now cleared");
                            return '';
                        });
                    }
                    
              
				} else {
					//this.$noty.error("Media usage agreement must be accepted for full functionality.");
					return '';
				}
			});
	},
}